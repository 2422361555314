<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.clone") }}
      <v-spacer></v-spacer>
      <v-btn color="success" @click="save" class="mr-2">
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn color="error" outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="12">
          <v-simple-table class="table-padding-2-no-top table-h-36">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th colspan="8" class="fs-16px primary--text">
                    {{ $t("labels.main_sku") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="font-weight-medium text-center">
                  <td>{{ $t("labels.image") }}</td>
                  <td>{{ $t("labels.sku") }}</td>
                  <td>{{ $t("labels.barcode") }}</td>
                  <td>{{ $t("labels.category") }}</td>
                  <td>{{ $t("labels.name") }}</td>
                  <td>{{ $t("labels.goods_description") }}</td>
                  <td>{{ $t("labels.size") }}</td>
                  <td>{{ $t("labels.file") }}</td>
                </tr>
                <tr class="text-center">
                  <td>
                    <ImageViewer
                      v-if="item.url_images"
                      :url="item.url_images"
                      width="40px"
                      height="40px"
                    />
                  </td>
                  <td>{{ item.sku }}</td>
                  <td>{{ item.customer_goods_barcode }}</td>
                  <td>{{ item.category_name }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.size }}</td>
                  <td>
                    <span
                      class="primary--text font-weight-medium cursor-pointer"
                      @click="downloadRecordFile"
                    >
                      {{ $t("labels.download") }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <!-- <v-col cols="12" md="12">
                    <SelectSuggestGoodsNoWarehouse @onSelect="selectItem" :select-items="items" :for-record="true" />
                </v-col> -->

        <v-col cols="12" md="12">
          <v-simple-table
            height="calc(100vh - 500px)"
            class="table-padding-2-no-top table-h-36"
          >
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th colspan="9" class="fs-16px primary--text">
                    {{ $t("labels.clone_sku") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="9">
                    <SelectSuggestGoodsNoWarehouse
                      @onSelect="selectItem"
                      :select-items="items"
                      :for-record="true"
                    />

                    <input
                      type="file"
                      ref="inputUploadFile"
                      accept="image/*,application/pdf"
                      @change="onInputFileChange"
                      class="d-none"
                      multiple
                    />
                  </td>
                </tr>
                <tr class="font-weight-medium text-center">
                  <td>{{ $t("labels.image") }}</td>
                  <td>{{ $t("labels.sku") }}</td>
                  <td>{{ $t("labels.barcode") }}</td>
                  <td>{{ $t("labels.category") }}</td>
                  <td>{{ $t("labels.name") }}</td>
                  <td>{{ $t("labels.goods_description") }}</td>
                  <td>{{ $t("labels.size") }}</td>
                  <td>{{ $t("labels.file") }}</td>
                  <th class="text-center" style="width: 75px">
                    <v-btn
                      v-if="items.length > 0"
                      color="error"
                      x-small
                      @click="clearAll()"
                    >
                      {{ $t("labels.clear") }}
                    </v-btn>
                  </th>
                </tr>
                <tr
                  class="text-center"
                  v-for="(cItem, cIndex) in items"
                  :key="`c_${cIndex}_${cItem.id}`"
                >
                  <td>
                    <ImageViewer
                      v-if="cItem.url_images"
                      :url="cItem.url_images"
                      width="40px"
                      height="40px"
                    />
                  </td>
                  <td>{{ cItem.sku }}</td>
                  <td>{{ cItem.customer_goods_barcode }}</td>
                  <td>{{ cItem.category_name }}</td>
                  <td>{{ cItem.name }}</td>
                  <td>{{ cItem.description }}</td>
                  <td>{{ cItem.size }}</td>
                  <td>
                    <span
                      class="primary--text font-weight-medium cursor-pointer"
                      @click="showUploadFile(cIndex)"
                    >
                      {{ $t("labels.upload") }}
                    </span>
                    <br />
                    <span v-if="cItem.files && cItem.files.length > 0">
                      ({{ cItem.files.length }})
                    </span>
                    <small v-else>(theo sku gốc)</small>
                  </td>
                  <td>
                    <v-btn
                      x-small
                      color="error"
                      @click="removeItem(cItem)"
                      class="mb-1"
                    >
                      {{ $t("labels.delete") }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { compress } from "image-conversion";
import moment from "moment";

export default {
  name: "RecordClone",
  components: {
    SelectSuggestGoodsNoWarehouse: () =>
      import("@/components/common/SelectSuggestGoodsNoWarehouse"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    files: null,
    isLoading: false,
    items: [],
    uploadIndex: null,
  }),
  computed: {},
  created() {},
  methods: {
    selectItem(item) {
      this.items.push(item);
    },
    removeItem(item) {
      const items = [...this.items].filter((it) => it.id !== item.id);
      this.items = [...items];
    },
    clearAll() {
      const items = [];
      this.items = [...items];
    },
    showUploadFile(index) {
      this.uploadIndex = index;
      this.$refs.inputUploadFile.click();
    },
    async uploadRecordFile() {},
    async downloadRecordFile() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = `${this.item.tracking_id}.zip`;

      try {
        await this.downloadExcelFile(
          "/download-goods-production-record-file",
          {
            id_goods_production_record: this.item.id,
          },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      let urls = [];
      for (let i = 0; i < files.length; i++) {
        const url = await this.uploadFile(files[i]);
        urls.push(url);
      }
      const items = [...this.items];
      items[this.uploadIndex].files = [...urls];
      this.items = [...items];
      this.uploadIndex = null;
      this.$refs.inputUploadFile.value = null;
      this.isLoading = false;
    },

    async uploadFile(file) {
      console.log(file);
      let fd = new FormData();
      if (file.type.includes("image")) {
        const resBlob = await compress(file, {
          quality: 0.85,
          type: "image/jpeg",
        });
        const newFile = new File(
          [resBlob],
          `${moment().unix()}_${this.generateRandomString(6)}.jpg`,
          { type: "image/jpeg" }
        );
        fd.append("file", newFile);
      } else {
        fd.append("file", file);
      }
      const { data } = await httpClient.post(`/upload-image`, fd);
      return data;
    },

    close() {
      this.$emit("close", true);
    },

    async save() {
      if (
        !confirm(
          this.$t("messages.goods_production_record_clone", {
            tracking: this.item.tracking_id,
          })
        )
      ) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-clone", {
          id_goods_production_record: this.item.id,
          items: this.items,
        });
        this.isLoading = false;
        const msg = this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.$emit("refreshData", true);
        this.close();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
